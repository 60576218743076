import React from "react";
import { useSelector } from "react-redux";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import { RootState } from "../../store/store";
import { ADMIN_ROUTES } from "../Navigations/AppNavigations";

export const PrivateRoute: React.FC<{ element: React.JSX.Element }> = ({
  element,
}) => {
  const getAdmin = useSelector((state: RootState) => state.adminAccount);
  if (!getAdmin.admin) {
    return <Navigate to={ADMIN_ROUTES.Auth} replace />;
  }
  return element ? <>{element}</> : <Outlet />;
};
