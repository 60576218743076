import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./slices/themeSlice";
// import userReducer from './slices/userSlice.ts.rx';
import courseReducer from "./slices/courseSlice";
import adminReducer from "./slices/adminSlice";
import AdminAuthReducer from "./slices/adminAuth";

// Middleware personnalisé pour transformer les réponses d'erreur
const transformErrorMiddleware = () => (next: any) => (action: any) => {
  // Vérifier si c'est une action rejetée
  if (action.type?.endsWith("/rejected")) {
    // Si l'erreur vient d'Axios, elle aura une structure spécifique
    if (action.payload?.response) {
      const { response } = action.payload;
      // Créer un nouvel objet d'erreur sérialisable
      const serializedError = {
        status: response.status,
        message: response.data?.message || "Une erreur est survenue",
        data: response.data,
        code: response.status,
      };

      return next({
        ...action,
        payload: serializedError,
      });
    }

    // Si c'est une erreur personnalisée
    if (action.payload && typeof action.payload === "object") {
      const serializedError = {
        status: action.payload.status || 500,
        message: action.payload.message || "Une erreur est survenue",
        data: action.payload.data,
        code: action.payload.code || "UNKNOWN_ERROR",
      };

      return next({
        ...action,
        payload: serializedError,
      });
    }
  }

  return next(action);
};

// Configuration du store avec des options de sérialisation étendues
export const store = configureStore({
  reducer: {
    theme: themeReducer,
    adminAccount: AdminAuthReducer,
    courses: courseReducer,
    admin: adminReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignorer les chemins problématiques connus
        ignoredActionPaths: ["payload.metadata"],
        ignoredPaths: ["user.currentUser.metadata"],
      },
    }).concat(transformErrorMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
