import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Users, BookOpen, Activity } from "lucide-react";
import "./styles/AdminDashboard.css";

export default function AdminDashboard() {
  const students = useSelector((state: RootState) => state.admin.students);

  const stats = [
    {
      icon: <Users size={24} />,
      label: "Total Students",
      value: students.length,
    },
    {
      icon: <BookOpen size={24} />,
      label: "Active Students",
      value: students.filter((s) => s.status === "connected").length,
    },
    {
      icon: <Activity size={24} />,
      label: "Average Progress",
      value: `${Math.round(
        students.reduce((acc, s) => acc + s.progress, 0) / students.length
      )}%`,
    },
  ];

  return (
    <div className="dashboard">
      <h1>Admin Dashboard</h1>

      <div className="statsContainer">
        {stats.map((stat, index) => (
          <div key={index} className="statCard">
            <div className="statIcon">{stat.icon}</div>
            <div className="statInfo">
              <h3>{stat.value}</h3>
              <p>{stat.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
