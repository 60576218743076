import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdminState,
  Student,
  StudentResult,
} from "../../NEHONIX/Interfaces/Interfaces";

const initialState: AdminState = {
  students: [],
  selectedStudent: null,
  loading: false,
  error: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setStudents: (state, action: PayloadAction<StudentResult[]>) => {
      state.students = action.payload;
    },
    selectStudent: (state, action: PayloadAction<string>) => {
      state.selectedStudent =
        state.students.find((s) => s.id === action.payload) || null;
    },
    updateStudent: (state, action: PayloadAction<StudentResult>) => {
      const index = state.students.findIndex((s) => s.id === action.payload.id);
      if (index !== -1) {
        state.students[index] = action.payload;
      }
    },
    deleteStudent: (state, action: PayloadAction<string>) => {
      state.students = state.students.filter((s) => s.id !== action.payload);
    },
  },
});

export const {
  setStudents: updateStudents,
  selectStudent,
  updateStudent,
  deleteStudent,
} = adminSlice.actions;
export default adminSlice.reducer;
