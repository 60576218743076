import React, { ReactNode, useEffect, useState } from "react";
import { Admin, updateAuthState } from "../store/slices/adminAuth";
import AdminApi from "../NEHONIX/Api/AdminApi";
import { useDispatch } from "react-redux";
import Loading from "../NEHONIX/Loading/Loading";
import { toast } from "react-toastify";

interface AuthContextI {
  loading: boolean;
}
export const AuthContext = React.createContext<AuthContextI>({ loading: true });
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const fetchAdminInfo = async () => {
    try {
      const res = await AdminApi.get("/auth/getInfo");
        dispatch(updateAuthState({ admin: res.data.admin }));
    } catch (error: any) {
      // toast.error(error.)
      console.log("Erreur - e182: ");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminInfo();
  }, []);

  return (
    <AuthContext.Provider value={{ loading }}>
      {loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
