import { CourseContent } from "../../../NEHONIX/Interfaces/Interfaces";
import { renderStyledContent } from "./RenderStyle";
import "./Styles/Preview.css";
function Preview({ courseContents }: { courseContents: CourseContent[] }) {
  return (
    <div className="course-Preview">
      {courseContents.map((course, id) => (
        <div key={id} className="course">
          {renderStyledContent(course)}
        </div>
      ))}
    </div>
  );
}

export default Preview;
