import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { selectStudent, deleteStudent } from "../../store/slices/adminSlice";
import { Edit2, Trash2 } from "lucide-react";
import "./styles/StudentList.css";

export default function StudentList() {
  const dispatch = useDispatch();
  const students = useSelector((state: RootState) => state.admin.students);

  const handleEdit = (id: string) => {
    dispatch(selectStudent(id));
  };

  const handleDelete = (id: string) => {
    if (window.confirm("Are you sure you want to delete this student?")) {
      dispatch(deleteStudent(id));
    }
  };

  return (
    <div className="studentList">
      <h2>Student Management</h2>

      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Progress</th>
              <th>Status</th>
              <th>Join Date</th>
              <th>Last Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student) => (
              <tr key={student.id}>
                <td>{student.name}</td>
                <td>{student.email}</td>
                <td>
                  <div className="progressBar">
                    <div
                      className="progressFill"
                      style={{ width: `${student.progress}%` }}
                    />
                  </div>
                </td>
                <td>
                  <span
                    style={{
                      color: student.status === "connected" ? "green" : "red",
                      fontWeight: "bold",
                    }}
                    className={`status ${student.status}`}
                  >
                    {student.status}
                  </span>
                </td>
                <td>{student.joinDate}</td>
                <td>{student.lastActive}</td>
                <td className="actions">
                  <button
                    aria-label="btn_actions"
                    type="button"
                    onClick={() => handleEdit(student.id)}
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    aria-label="btn_actions"
                    type="button"
                    onClick={() => handleDelete(student.id)}
                  >
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
