import { toast } from "react-toastify";
import AdminApi from "../../../NEHONIX/Api/AdminApi";
import { PublishCourseT } from "../../../NEHONIX/Interfaces/Interfaces";

export async function OnSubmitCourse(data: PublishCourseT) {
  try {
    const res = await AdminApi.post("/courses/publish", { courseData: data });
    console.log("res: ", res);
    toast.success("Cours publié avec succès.");
  } catch (error: any) {
    console.log("Error: ", error);
  }
}
