import { toast } from "react-toastify";
import AdminApi from "../../../Api/AdminApi";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthState } from "../../../../store/slices/adminAuth";
import { useEffect } from "react";
import { RootState } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../Navigations/AppNavigations";

export const useSubmitAuth = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.adminAccount);
  const navigate = useNavigate();

  const SubmitAuth = async (data: { mdp: string; userName: string }) => {
    try {
      const res = await AdminApi.post("/auth/connexion", {data});
      console.log("Response: ", res);

      const response = res.data.admin;
      dispatch(updateAuthState({ admin: response }));

      toast.success("Connexion réussie");
      navigate(ADMIN_ROUTES.Home);

      return response;
    } catch (error: any) {
      if (error?.response?.status === 404) {
        toast.error("Aucune donnée trouvée");
      } else {
        toast.error("Une erreur s'est produite");
      }
      console.log("Error: ", error);
    }
  };

  return { SubmitAuth };
};
