import React from 'react';
import { Loader2 } from 'lucide-react';
import './Style/Loading.css';

interface LoadingProps {
  message?: string;
  size?: number;
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({ 
  message = "Chargement en cours...", 
  size = 48, 
  color = "#007BFF" 
}) => {
  return (
    <div className="loading-overlay">
      <div className="loading-container">
        <Loader2 
          size={size} 
          color={color} 
          className="loading-icon"
        />
        <p className="loading-message">
          {message}
        </p>
      </div>
    </div>
  );
};

export default Loading;