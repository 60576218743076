import React, { useEffect, useState } from "react";
import { Lock, User, EyeOff, Eye } from "lucide-react";
import "./Style/AdminAuth.css";
import { useSubmitAuth } from "./OnFormSubmit/SubmitAuth";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const Auth = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { SubmitAuth } = useSubmitAuth();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

   
    
    
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Logique d'authentification
    if (!formData.password) {
      toast.error("Aucune donnée réçu");
      return;
    }
    const data = {
      mdp: formData.password,
      userName: formData.username,
      role: "admin"
    };
    SubmitAuth(data);
  };

    
    
  return (
    <div className="auth-container">
      <div className="auth-box">
        <div className="auth-header">
          <div className="auth-icon">
            <Lock />
          </div>
          <h1>Administration</h1>
          <p>Connectez-vous à votre espace</p>
        </div>

        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <div className="input-icon">
              <User />
            </div>
            <input
              type="text"
              placeholder="Nom d'utilisateur"
              value={formData.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <div className="input-icon">
              <Lock />
            </div>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Mot de passe"
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            <button
              type="button"
              className="toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOff /> : <Eye />}
            </button>
          </div>

          <button type="submit" className="submit-button">
            Se connecter
          </button>
        </form>

        <div className="forgot-password">
          <a href="#">Mot de passe oublié ?</a>
        </div>
      </div>
    </div>
  );
};

export default Auth;
