import AdminApi from "../../NEHONIX/Api/AdminApi";
import { Course } from "../../NEHONIX/Interfaces/Interfaces";

class CourseService {
  async updateCourse(courseId: string, courseData: Course): Promise<Course> {
    try {
      const response = await AdminApi.put(`/courses/${courseId}`, courseData);
      return response.data;
    } catch (error) {
      console.error("Error updating course:", error);
      throw error;
    }
  }
  // Fetch all published courses
  async fetchCourses(): Promise<Course[]> {
    try {
      const response = await AdminApi.get(`/courses`);
      return response.data;
    } catch (error) {
      console.error("Error fetching courses:", error);
      throw error;
    }
  }

  // Delete a course by ID
  async deleteCourse(courseId: string): Promise<void> {
    try {
      await AdminApi.delete(`/courses/${courseId}`);
    } catch (error) {
      console.error("Error deleting course:", error);
      throw error;
    }
  }

  // Update course status
  async updateCourseStatus(
    courseId: string,
    status: "available" | "unAvailable"
  ): Promise<Course> {
    try {
      const response = await AdminApi.patch(`/courses/${courseId}/status`, {
        status,
      });
      return response.data;
    } catch (error) {
      console.error("Error updating course status:", error);
      throw error;
    }
  }
}
const instance = new CourseService();

export default instance;
