import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { updateStudent } from "../../store/slices/adminSlice";
import "./styles/StudentForm.css";
import { Status } from "../../NEHONIX/Interfaces/Interfaces";

export default function StudentForm() {
  const dispatch = useDispatch();
  const selectedStudent = useSelector(
    (state: RootState) => state.admin.selectedStudent
  );

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    status: "connected" as Status,
    progress: 0,
  });

  useEffect(() => {
    if (selectedStudent) {
      setFormData(selectedStudent);
    }
  }, [selectedStudent]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      updateStudent({
        ...formData,
        joinDate:
          selectedStudent?.joinDate || new Date().toISOString().split("T")[0],
        lastActive: new Date().toISOString().split("T")[0],
      })
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="formContainer">
      <h2>{selectedStudent ? "Edit Student" : "Add New Student"}</h2>

      <form onSubmit={handleSubmit} className="form">
        <div className="formGroup">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="formGroup">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="formGroup">
          <label htmlFor="status">Status</label>
          <select
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>

        <div className="formGroup">
          <label htmlFor="progress">Progress (%)</label>
          <input
            type="number"
            id="progress"
            name="progress"
            value={formData.progress}
            onChange={handleChange}
            min="0"
            max="100"
          />
        </div>

        <button type="submit" className="submitButton">
          {selectedStudent ? "Update Student" : "Add Student"}
        </button>
      </form>
    </div>
  );
}
