import {
  Bold,
  Italic,
  Underline,
  AlignCenter,
  AlignLeft,
  AlignRight,
  Eye,
} from "lucide-react";
import {
  ContentStyle,
  CourseContent,
} from "../../../NEHONIX/Interfaces/Interfaces";
import { handleFileUpload } from "../Utils/handleUpload";
import React, { useState } from "react";

function StyleToolBar({
  applyStyle,
  currentStyles,
  setCourseContents,
  setPreview,
  preview,
}: {
  applyStyle: (style: Partial<ContentStyle>) => void;
  currentStyles: ContentStyle;
  setCourseContents: React.Dispatch<React.SetStateAction<CourseContent[]>>;
  setPreview: React.Dispatch<React.SetStateAction<boolean>>;
  preview: boolean;
}) {
  const [imageWidth, setImageWidth] = useState("300");

  return (
    <div className="styling-toolbar">
      <button
        type="button"
        aria-label="buttons"
        onClick={() => applyStyle({ bold: !currentStyles.bold })}
        className={currentStyles.bold ? "active" : ""}
      >
        <Bold size={20} />
      </button>

      <button
        type="button"
        aria-label="buttons"
        onClick={() => setPreview(!preview)}
        className={`${currentStyles.bold ? "active" : ""}`}
      >
        <Eye size={20} />
      </button>

      <button
        type="button"
        aria-label="buttons"
        onClick={() => applyStyle({ italic: !currentStyles.italic })}
        className={currentStyles.italic ? "active" : ""}
      >
        <Italic size={20} />
      </button>
      <button
        type="button"
        aria-label="buttons"
        onClick={() => applyStyle({ underline: !currentStyles.underline })}
        className={currentStyles.underline ? "active" : ""}
      >
        <Underline size={20} />
      </button>

      <select
        aria-label="color"
        onChange={(e) => applyStyle({ color: e.target.value })}
        value={currentStyles.color || "#000000"}
      >
        <option value="#000000">Noir</option>
        <option value="#FF0000">Rouge</option>
        <option value="#0000FF">Bleu</option>
        <option value="#00FF00">Vert</option>
      </select>

      <select
        aria-label="select"
        onChange={(e) => applyStyle({ fontSize: e.target.value })}
        value={currentStyles.fontSize || "16px"}
      >
        <option value="12px">Petit</option>
        <option value="16px">Normal</option>
        <option value="20px">Grand</option>
        <option value="24px">Très grand</option>
      </select>

      <div>
        <button
          aria-label="buttons"
          onClick={() => applyStyle({ alignment: "left" })}
        >
          <AlignLeft size={20} />
        </button>
        <button
          aria-label="buttons"
          onClick={() => applyStyle({ alignment: "center" })}
        >
          <AlignCenter size={20} />
        </button>
        <button
          aria-label="buttons"
          onClick={() => applyStyle({ alignment: "right" })}
        >
          <AlignRight size={20} />
        </button>
      </div>

      <div className="image-upload-section">
        <input
          placeholder="file"
          type="file"
          onChange={(e) => handleFileUpload(e, setCourseContents, imageWidth)}
          accept="image/*"
          className="file-upload"
        />
        <div className="image-width-control">
          <label>Largeur de l'image (px) :</label>
          <input
            placeholder="largeur"
            type="number"
            value={imageWidth}
            onChange={(e) => setImageWidth(e.target.value)}
            min="50"
            max="800"
          />
        </div>
      </div>
    </div>
  );
}

export default StyleToolBar;
