import { useDispatch, useSelector } from "react-redux";
import AdminApi from "../../NEHONIX/Api/AdminApi";
import {
  FetchStudentResult,
  StudentResult,
} from "../../NEHONIX/Interfaces/Interfaces";
import { updateStudents } from "../../store/slices/adminSlice";

export const useFetchAllStudents = () => {
  //   const dispatch = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch();

  const FetchAllStudents = async () => {
    try {
      const res = await AdminApi.get("/students/getInfo");
      const fetchResult: FetchStudentResult = res.data.users;
      // Transformation directe avec map
      const formattedResults: StudentResult[] = fetchResult.map(
        (topLevelItem) => {
          const joinDate = new Date(topLevelItem.metadata.createdAt);
          return {
            id: topLevelItem.id,
            name: topLevelItem.name,
            email: topLevelItem.email,
            status: topLevelItem.status,
            progress: 0,
            joinDate: joinDate.toString(),
            lastActive: topLevelItem.metadata.lastLoginAt,
          };
        }
      );
      dispatch(updateStudents(formattedResults));
      return formattedResults;
    } catch (error: any) {
      console.log("Une erreur s'est produite lors du fetch: ", error);
      // throw error;
    }
  };
  return { FetchAllStudents };
};
