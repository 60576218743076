import TextEditor from "./TextEditor";

export default function CreateCourse() {
  return (
    <div className="CreateCourse">
      <h1 className="CreateCourse-title">Créateur de Cours</h1>
      <TextEditor />
    </div>
  );
}
