import { z } from "zod";

export const courseSchema = z.object({
  title: z.string().min(3, "Titre requis"),
  description: z.string().min(10, "Description requise"),
  thumbnail: z.string().optional(),
  courseFile: z.string().optional(),
  duration: z.string(),
  date: z.string(),
  level: z.enum(["backend", "frontend"]),
  status: z.enum(["available", "unAvailable"]),
  instructor: z.object({
    name: z.string().min(2, "Nom requis"),
    avatar: z.string().optional(),
  }),
});

// Schéma de validation Zod basé sur l'interface Course
export const PublishCourseSchema = z.object({
  title: z.string().min(3, "Le titre doit contenir au moins 3 caractères"),
  description: z
    .string()
    .min(10, "La description doit contenir au moins 10 caractères"),
  hours: z.string().min(3, "L'heure doit contenir au moins 10 caractères"),
  AccessCode: z
    .string()
    .min(3, "AccessCode doit contenir au moins 10 caractères"),
  SecurityCode: z
    .string()
    .min(3, "SecurityCode doit contenir au moins 10 caractères"),
  PhoneCall: z
    .string()
    .min(3, "SecurityCode doit contenir au moins 10 caractères"),
  thumbnail: z.string().url("Doit être une URL valide"),
  duration: z.string(),
  date: z.string().refine((val) => !isNaN(Date.parse(val)), "Date invalide"),
  level: z.enum(["backend", "frontend"]),
  url: z.string().url("Doit être une URL valide"),
  status: z.enum(["available", "unAvailable"]),
  instructor: z.object({
    name: z
      .string()
      .min(2, "Le nom de l'instructeur doit contenir au moins 2 caractères"),
    avatar: z.string().url("L'avatar doit être une URL valide"),
  }),
});
