import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import "./styles/PublishCourse.css";
import { PublishCourseSchema as courseSchema } from "../../NEHONIX/Schemas/CourseSchema";
import { PublishCourseType } from "../../NEHONIX/Interfaces/Interfaces";
import { OnSubmitCourse } from "./Utils/OnPublishedCourse";

function PublishCourse() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PublishCourseType>({
    resolver: zodResolver(courseSchema),
  });

  const onSubmit = (data: PublishCourseType) => {
    const date = data.hours.toLowerCase().trim().split(":");
    const hours = date[0] + "h" + " " + date[1] + "min";

    // console.log({ ...data, hours});
    OnSubmitCourse({ ...data, hours });
    // Logique de soumission du formulaire
  };

  return (
    <div className="publish-course-container">
      <form onSubmit={handleSubmit(onSubmit)} className="course-form">
        <h2>Publier un nouveau cours</h2>

        <div className="form-group">
          <label htmlFor="title">Titre du cours</label>
          <input
            type="text"
            {...register("title")}
            className={errors.title ? "error" : ""}
          />
          {errors.title && (
            <span className="error-message">{errors.title.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            {...register("description")}
            className={errors.description ? "error" : ""}
          />
          {errors.description && (
            <span className="error-message">{errors.description.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="thumbnail">URL de la miniature</label>
          <input
            type="text"
            {...register("thumbnail")}
            className={errors.thumbnail ? "error" : ""}
          />
          {errors.thumbnail && (
            <span className="error-message">{errors.thumbnail.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="hours">L'heure</label>
          <input
            type="time"
            // defaultValue={"A"}
            {...register("hours")}
            className={errors.hours ? "error" : ""}
          />
          {errors.hours && (
            <span className="error-message">{errors.hours.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="hours">Code D'accès</label>
          <input
            type="text"
            // defaultValue={"A"}
            {...register("AccessCode")}
            className={errors.AccessCode ? "error" : ""}
          />
          {errors.AccessCode && (
            <span className="error-message">{errors.AccessCode.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="hours">Code de sécurité</label>
          <input
            type="text"
            // defaultValue={"A"}
            {...register("SecurityCode")}
            className={errors.SecurityCode ? "error" : ""}
          />
          {errors.SecurityCode && (
            <span className="error-message">{errors.SecurityCode.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="hours">Numéro d'urgence</label>
          <input
            type="text"
            defaultValue={"+1 605-313-5446"}
            {...register("PhoneCall")}
            className={errors.PhoneCall ? "error" : ""}
          />
          {errors.PhoneCall && (
            <span className="error-message">{errors.PhoneCall.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="duration">Durée du cours</label>
          <input
            type="text"
            {...register("duration")}
            className={errors.duration ? "error" : ""}
          />
          {errors.duration && (
            <span className="error-message">{errors.duration.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            {...register("date")}
            className={errors.date ? "error" : ""}
          />
          {errors.date && (
            <span className="error-message">{errors.date.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="level">Niveau</label>
          <select
            {...register("level")}
            className={errors.level ? "error" : ""}
          >
            <option value="">Sélectionnez un niveau</option>
            <option value="backend">Backend</option>
            <option value="frontend">Frontend</option>
          </select>
          {errors.level && (
            <span className="error-message">{errors.level.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="url">URL du cours</label>
          <input
            type="text"
            {...register("url")}
            className={errors.url ? "error" : ""}
          />
          {errors.url && (
            <span className="error-message">{errors.url.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="status">Statut</label>
          <select
            {...register("status")}
            className={errors.status ? "error" : ""}
          >
            <option value="">Sélectionnez un statut</option>
            <option value="available">Disponible</option>
            <option value="unAvailable">Non disponible</option>
          </select>
          {errors.status && (
            <span className="error-message">{errors.status.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="instructor.name">Nom de l'instructeur</label>
          <input
            type="text"
            {...register("instructor.name")}
            className={errors.instructor?.name ? "error" : ""}
          />
          {errors.instructor?.name && (
            <span className="error-message">
              {errors.instructor.name.message}
            </span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="instructor.avatar">Avatar de l'instructeur</label>
          <input
            type="text"
            {...register("instructor.avatar")}
            className={errors.instructor?.avatar ? "error" : ""}
          />
          {errors.instructor?.avatar && (
            <span className="error-message">
              {errors.instructor.avatar.message}
            </span>
          )}
        </div>

        <button type="submit" className="submit-button">
          Publier le cours
        </button>
      </form>
    </div>
  );
}

export default PublishCourse;
