import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { store } from "./store/store";
import { AppRouter as AdminAppRouter } from "./router/AppRouter";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global.css";
import { useEffect } from "react";
import Auth from "./NEHONIX/Private/Auth/Auth";
import { AuthProvider } from "./Contexts/AuthContext";
import { useFetchAllStudents } from "./components/StudentInteract/FetchAllStudents";

function App() {
  const { FetchAllStudents } = useFetchAllStudents();
  const AppRouter = createBrowserRouter([
    ...AdminAppRouter.routes,
    {
      path: "/admin/auth",
      element: <Auth />,
    },
  ]);

  useEffect(() => {
    FetchAllStudents();
  }, []);

  const path = window.location.pathname;
  useEffect(() => {
    if (path === "/") {
      window.location.replace("/admin");
    }
    // console.log(window.location.pathname);
  }, [path]);

  return (
    <div className="app">
      <ToastContainer />
      <AuthProvider>
        <RouterProvider router={AppRouter} />
      </AuthProvider>
    </div>
  );
}

export default App;
