import { NavLink } from "react-router-dom";
import { LayoutDashboard, Users, Settings } from "lucide-react";
import "./styles/AdminSidebar.css";

export default function AdminSidebar() {
  const navItems = [
    { icon: <LayoutDashboard size={20} />, label: "Dashboard", path: "/admin" },
    { icon: <Users size={20} />, label: "Students", path: "/admin/students" },
    {
      icon: <Users size={20} />,
      label: "Published",
      path: "/admin/nehonix/overview/course",
    },
    {
      icon: <Users size={20} />,
      label: "Create Course",
      path: "/admin/nehonix/publish/course",
    },
    // {
    //   icon: <Settings size={20} />,
    //   label: "Settings",
    //   path: "/admin/settings",
    // },
  ];

  return (
    <aside className="sidebar">
      <div className="logo">
        <img src="/logo.svg" alt="NEHONIX Admin" />
        <h1>Admin Panel</h1>
      </div>

      <nav className="nav">
        {navItems.map((item) => (
          <NavLink
            end
            key={item.path}
            to={item.path}
            className={({ isActive }) => `navItem ${isActive ? "active" : ""}`}
          >
            {item.icon}
            <span>{item.label}</span>
          </NavLink>
        ))}
      </nav>
    </aside>
  );
}
