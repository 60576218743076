import { CourseContent } from "../../../NEHONIX/Interfaces/Interfaces";

export const handleFileUpload = (
  event: React.ChangeEvent<HTMLInputElement>,
  setCourseContents: React.Dispatch<React.SetStateAction<CourseContent[]>>,
  imageWidth: string
) => {
  const file = event.target.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const newImageContent: CourseContent = {
        type: "image",
        content: reader.result as string,
        styles: {
          width: `${imageWidth}px`,
          height: "auto",
        },
      };
      setCourseContents((prev) => [...prev, newImageContent]);
    };
    reader.readAsDataURL(file);
  }
};
