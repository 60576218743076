import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Slice Redux
export interface Admin {
  name: string;
  role: "admin" | undefined;
  username: string;
}

export interface AdminAuthI {
  admin: Admin | null;
}

export const initialState: AdminAuthI = {
  admin: null,
};

export const AdminAuthSlice = createSlice({
  name: "admin/auth",
  initialState,
  reducers: {
    updateAuthState: (state, action: PayloadAction<AdminAuthI>) => {
      state.admin = action.payload.admin;
    },
  },
});

export const { updateAuthState } = AdminAuthSlice.actions;
export default AdminAuthSlice.reducer;
