import React, { useState, useEffect } from "react";
import CourseService from "./CourseService"; // Adjust import path as needed
import "./styles/PublishedCourse.css";
import EditCourseModal from "./EditCourseModal";

export interface Course {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  duration: string;
  date: string;
  level: "backend" | "frontend";
  url: string;
  status: "available" | "unAvailable";
  hours: string;
  AccessCode: string;
  SecurityCode: string;
  PhoneCall: string;
  instructor: {
    name: string;
    avatar: string;
  };
}

function PublishedCourse() {
  const [courses, setCourses] = useState<Course[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [courseToEdit, setCourseToEdit] = useState<Course | null>(null);

  const [filter, setFilter] = useState<{
    level: string;
    status: string;
    search: string;
  }>({
    level: "",
    status: "",
    search: "",
  });

  // Fetch courses
  const fetchCourses = async () => {
    try {
      setIsLoading(true);
      const fetchedCourses = await CourseService.fetchCourses();
      setCourses(fetchedCourses);
      setError(null);
    } catch (err) {
      setError("Impossible de charger les cours. Veuillez réessayer.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchCourses();
  }, []);

  // Filter courses
  const filteredCourses = courses.filter(
    (course) =>
      (filter.level ? course.level === filter.level : true) &&
      (filter.status ? course.status === filter.status : true) &&
      (filter.search
        ? course.title.toLowerCase().includes(filter.search.toLowerCase())
        : true)
  );

  // Handle course deletion
  const handleDeleteCourse = async (courseId: string) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce cours ?")) {
      try {
        await CourseService.deleteCourse(courseId);
        setCourses(courses.filter((course) => course.id !== courseId));
      } catch (err) {
        alert("Erreur lors de la suppression du cours");
        console.error(err);
      }
    }
  };

  // Handle course status toggle
  const handleToggleStatus = async (courseId: string) => {
    try {
      const courseToUpdate = courses.find((course) => course.id === courseId);
      if (!courseToUpdate) return;

      const newStatus =
        courseToUpdate.status === "available" ? "unAvailable" : "available";
      const updatedCourse = await CourseService.updateCourseStatus(
        courseId,
        newStatus
      );

      setCourses(
        courses.map((course) =>
          course.id === courseId ? updatedCourse : course
        )
      );
    } catch (err) {
      alert("Erreur lors de la mise à jour du statut");
      console.error(err);
    }
  };

  // Render loading state
  if (isLoading) {
    return <div className="loading">Chargement des cours...</div>;
  }

  // Render error state
  if (error) {
    return <div className="error">{error}</div>;
  }

  const handleEditCourse = async (updatedCourse: Course) => {
    try {
      // Call service to update course
      const result = await CourseService.updateCourse(
        updatedCourse.id,
        updatedCourse
      );

      // Update courses in state
      setCourses(
        courses.map((course) =>
          course.id === updatedCourse.id ? result : course
        )
      );

      // Close the modal
      setCourseToEdit(null);
    } catch (err) {
      alert("Erreur lors de la mise à jour du cours");
      console.error(err);
    }
  };

  return (
    <div className="published-course-container">
      <h1>Cours Publiés</h1>

      {/* Filtering Section (same as previous implementation) */}
      <div className="filter-section">
        <input
          type="text"
          placeholder="Rechercher des cours..."
          value={filter.search}
          onChange={(e) => setFilter({ ...filter, search: e.target.value })}
        />

        <select
          aria-label="filter-level"
          value={filter.level}
          onChange={(e) => setFilter({ ...filter, level: e.target.value })}
        >
          <option value="">Tous les niveaux</option>
          <option value="backend">Backend</option>
          <option value="frontend">Frontend</option>
        </select>

        <select
          aria-label="filter-status"
          value={filter.status}
          onChange={(e) => setFilter({ ...filter, status: e.target.value })}
        >
          <option value="">Tous les statuts</option>
          <option value="available">Disponible</option>
          <option value="unAvailable">Non disponible</option>
        </select>
      </div>

      {/* Courses List (similar to previous implementation) */}
      <div className="courses-list">
        {filteredCourses.map((course) => (
          <div key={course.id} className="course-card">
            <img
              src={course.thumbnail}
              alt={course.title}
              className="course-thumbnail"
            />

            <div className="course-details">
              <h2>{course.title}</h2>
              <p>{course.description}</p>

              <div className="course-info">
                <span>Niveau: {course.level}</span>
                <span>Durée: {course.duration}</span>
                <span>Date: {course.date}</span>
                <span>
                  Statut:
                  <span className={`status ${course.status}`}>
                    {course.status === "available"
                      ? "Disponible"
                      : "Non disponible"}
                  </span>
                </span>
              </div>

              <div className="course-instructor">
                <img
                  src={course.instructor?.avatar}
                  alt={course.instructor?.name}
                />
                <span>{course.instructor?.name}</span>
              </div>

              <div className="course-actions">
                <button
                  type="button"
                  onClick={() => setCourseToEdit(course)}
                  className="edit-course"
                >
                  Modifier
                </button>
                <button
                  type="button"
                  onClick={() => handleToggleStatus(course.id)}
                  className={`status-toggle ${course.status}`}
                >
                  {course.status === "available" ? "Désactiver" : "Activer"}
                </button>

                <button
                  type="button"
                  onClick={() => handleDeleteCourse(course.id)}
                  className="delete-course"
                >
                  Supprimer
                </button>

                <a
                  href={course.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="view-course"
                >
                  Voir le cours
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      {filteredCourses.length === 0 && (
        <div className="no-courses">Aucun cours trouvé.</div>
      )}

      {/* Edit Modal - Conditionally Rendered */}
      {courseToEdit && (
        <EditCourseModal
          course={courseToEdit}
          onClose={() => setCourseToEdit(null)}
          onSave={handleEditCourse}
        />
      )}
    </div>
  );
}

export default PublishedCourse;
