import { Share2Icon, Type, Upload } from "lucide-react";
import { useRef, useState } from "react";
import {
  ContentStyle,
  CourseContent,
} from "../../../NEHONIX/Interfaces/Interfaces";
import { renderStyledContent } from "./RenderStyle";
import "./Styles/EditorFeatures.css";
interface EditorProps {
  currentStyles: ContentStyle;
  courseContents: CourseContent[];
  courseTitle: string;
  setCourseContents: React.Dispatch<React.SetStateAction<CourseContent[]>>;
  setCurrentStyles: React.Dispatch<React.SetStateAction<ContentStyle>>;
}

function EditorFeatures({
  currentStyles,
  setCourseContents,
  setCurrentStyles,
  courseContents,
  courseTitle,
}: EditorProps) {
  const [currentContent, setCurrentContent] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const addContentBlock = () => {
    if (currentContent.trim()) {
      const newContent: CourseContent = {
        type: "text",
        content: currentContent,
        styles: { ...currentStyles },
      };

      setCourseContents((prev) => [...prev, newContent]);
      setCurrentContent("");
      setCurrentStyles({});
      console.log("currentContent: ", currentContent);
      console.log("courseContents: ", courseContents);
    }
  };

    // const Submit

  return (
    <div>
      <textarea
        ref={textareaRef}
        value={currentContent}
        onChange={(e) => setCurrentContent(e.target.value)}
        placeholder="Saisissez votre contenu..."
        style={{
          ...currentStyles,
          width: "100%",
          minHeight: "100px",
        }}
        className="content-input"
      />

      <button
        onClick={addContentBlock}
        className="add-content-btn"
        disabled={!currentContent.trim()}
      >
        <Type size={20} />
        Ajouter au cours
      </button>

      <div className="course-preview">
        <h2>Aperçu du Cours</h2>
        {courseContents.map((item, index) => (
          <div key={index} className="preview-item">
            {renderStyledContent(item)}
            <button
              onClick={() =>
                setCourseContents((prev) => prev.filter((_, i) => i !== index))
              }
              className="delete-item-btn"
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>

      <button
        className="save-course-btn"
        disabled={!courseTitle || courseContents.length === 0}
      >
        <Upload size={20} />
        Sauvegarder le Cours
      </button>

      <button
        className="publish-course-btn"
        disabled={!courseTitle || courseContents.length === 0}
      >
        <Share2Icon size={20} />
        Publier
      </button>
    </div>
  );
}

export default EditorFeatures;
