import { CourseContent } from "../../../NEHONIX/Interfaces/Interfaces";

export const renderStyledContent = (content: CourseContent) => {
  const baseStyle: React.CSSProperties = {};

  if (content.styles) {
    const {
      bold,
      italic,
      underline,
      color,
      fontSize,
      alignment,
      width,
      height,
    } = content.styles;

    if (bold) baseStyle.fontWeight = "bold";
    if (italic) baseStyle.fontStyle = "italic";
    if (underline) baseStyle.textDecoration = "underline";
    if (color) baseStyle.color = color;
    if (fontSize) baseStyle.fontSize = fontSize;
    if (alignment) baseStyle.textAlign = alignment;
    if (width) baseStyle.width = width;
    if (height) baseStyle.height = height;
  }

  if (content.type === "text") {
    return <p style={baseStyle}>{content.content}</p>;
  } else if (content.type === "image") {
    return (
      <img src={content.content} alt="Contenu du cours" style={baseStyle} />
    );
  }
  return null;
};
