import React, { useState } from "react";
import axios from "axios";
import { Clock, Link2 } from "lucide-react";
import AdminApi from "../../Api/AdminApi";
import "./Style/MeetingForm.css"

function CreateMeetingForm() {
  const [meetingData, setMeetingData] = useState({
    url: "",
    duration: 30,
    title: "",
  });

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setMeetingData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await AdminApi.post("/create-meeting", meetingData);
      alert(response.data.message);
    } catch (error) {
      console.error("Erreur de création:", error);
      alert("Échec de création de la réunion");
    }
  };

  return (
    <div className="meeting-form-container">
      <form onSubmit={handleSubmit} className="meeting-form">
        <div className="form-group">
          <label htmlFor="url" className="form-label">
            <Link2 size={20} className="input-icon" />
            URL de la réunion
          </label>
          <input
            type="url"
            id="url"
            value={meetingData.url}
            onChange={handleChange}
            className="form-input"
            placeholder="https://exemple.com/reunion"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="duration" className="form-label">
            <Clock size={20} className="input-icon" />
            Durée (minutes)
          </label>
          <input
            type="number"
            id="duration"
            value={meetingData.duration}
            onChange={handleChange}
            className="form-input"
            min="15"
            max="240"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="title" className="form-label">
            Titre de la réunion
          </label>
          <input
            type="text"
            id="title"
            value={meetingData.title}
            onChange={handleChange}
            className="form-input"
            placeholder="Réunion de projet"
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Créer la réunion
        </button>
      </form>
    </div>
  );
}

export default CreateMeetingForm;
