import React, { useState, useEffect } from "react";
import "./styles/EditCourseModal.css";
import { Course } from "../../NEHONIX/Interfaces/Interfaces";

interface EditCourseModalProps {
  course: Course;
  onClose: () => void;
  onSave: (updatedCourse: Course) => void;
}

function EditCourseModal({ course, onClose, onSave }: EditCourseModalProps) {
  const [editedCourse, setEditedCourse] = useState<Course>(course);

  // Handle input changes
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    // Handle nested instructor fields
    if (name.startsWith("instructor.")) {
      const instructorField = name.split(".")[1];
      setEditedCourse((prev) => ({
        ...prev,
        instructor: {
          ...prev.instructor,
          [instructorField]: value,
        },
      }));
    } else {
      setEditedCourse((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(editedCourse);
  };

  return (
    <div className="edit-course-modal-overlay">
      <div className="edit-course-modal">
        <h2>Modifier le cours</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            {/* Course Details */}
            <div className="form-group">
              <label>Titre du cours</label>
              <input
                placeholder="Titre du cours"
                type="text"
                name="title"
                value={editedCourse.title}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                placeholder="description du cours"
                name="description"
                value={editedCourse.description}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>URL de la miniature</label>
              <input
                type="text"
                placeholder="URL de la miniature du cours"
                name="thumbnail"
                value={editedCourse.thumbnail}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Durée</label>
              <input
                placeholder="Durée du cours"
                type="text"
                name="duration"
                value={editedCourse.duration}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Date</label>
              <input
                placeholder="Date du cours"
                type="date"
                name="date"
                value={editedCourse.date}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Niveau</label>
              <select
                aria-label="Niveau"
                name="level"
                value={editedCourse.level}
                onChange={handleChange}
              >
                <option value="backend">Backend</option>
                <option value="frontend">Frontend</option>
              </select>
            </div>

            <div className="form-group">
              <label>URL du cours</label>
              <input
                placeholder="URL du cours du cours"
                type="text"
                name="url"
                value={editedCourse.url}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Statut</label>
              <select
                aria-label="status"
                name="status"
                value={editedCourse.status}
                onChange={handleChange}
              >
                <option value="available">Disponible</option>
                <option value="unAvailable">Non disponible</option>
              </select>
            </div>

            <div className="form-group">
              <label>Heure</label>
              <input
                placeholder="Heure du cours"
                type="text"
                name="hours"
                value={editedCourse.hours}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Code d'accès</label>
              <input
                placeholder="Code d'accès du cours"
                type="text"
                name="AccessCode"
                value={editedCourse.AccessCode}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Code de sécurité</label>
              <input
                placeholder="Code de sécurité du cours"
                type="text"
                name="SecurityCode"
                value={editedCourse.SecurityCode}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Numéro d'urgence</label>
              <input
                placeholder="Numéro d'urgence du cours"
                type="text"
                name="PhoneCall"
                value={editedCourse.PhoneCall}
                onChange={handleChange}
              />
            </div>

            {/* Instructor Details */}
            <div className="form-group">
              <label>Nom de l'instructeur</label>
              <input
                placeholder="Nom de l'instructeur"
                type="text"
                name="instructor.name"
                value={editedCourse.instructor.name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Avatar de l'instructeur</label>
              <input
                placeholder="Avatar de l'instructeur"
                type="text"
                name="instructor.avatar"
                value={editedCourse.instructor.avatar}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="modal-actions">
            <button type="button" className="cancel" onClick={onClose}>
              Annuler
            </button>
            <button type="submit" className="save">
              Enregistrer les modifications
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCourseModal;
