import { Outlet } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import "./styles/AdminLayout.css";

export default function AdminLayout() {
  return (
    <div className="adminLayout">
      <AdminSidebar />
      <main className="mainContent">
        <Outlet />
      </main>
    </div>
  );
}
