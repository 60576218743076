import { createBrowserRouter } from "react-router-dom";
import AdminLayout from "../components/admin/AdminLayout";
import AdminDashboard from "../components/admin/AdminDashboard";
import StudentList from "../components/admin/StudentList";
import StudentForm from "../components/admin/StudentForm";
import { PrivateRoute } from "../NEHONIX/Private/PrivateRoot";
import AdminVideoCall from "../NEHONIX/Video/AdminVideoCall";
import PublishCourse from "../components/admin/PublishCourse";
import PublishedCourse from "../components/admin/PublishedCourse";
import CreateCourse from "../components/admin/CreateCourse";

export const AppRouter = createBrowserRouter([
  {
    path: "/admin",
    element: <PrivateRoute element={<AdminLayout />} />,
    children: [
      { path: "", element: <PrivateRoute element={<AdminDashboard />} /> },
      { path: "students", element: <PrivateRoute element={<StudentList />} /> },
      {
        path: "students/edit/:id",
        element: <PrivateRoute element={<StudentForm />} />,
      },
      {
        path: "students/new",
        element: <PrivateRoute element={<StudentForm />} />,
      },
      {
        path: "nehonix/overview/course",
        element: <PrivateRoute element={<PublishedCourse />} />,
      },
      {
        path: "nehonix/publish/course",
        element: <PrivateRoute element={<PublishCourse />} />,
      },
    ],
  },
  {
    path: "/nehonix/create/course",
    element: <PrivateRoute element={<CreateCourse />} />,
  },

  {
    path: "/nehonix/meet/create",
    element: <PrivateRoute element={<AdminVideoCall isAdmin />} />,
  },
]);
