import axios from "axios";
import { toast } from "react-toastify";
import { ADMIN_ROUTES } from "../Navigations/AppNavigations";

// Crée une instance Axios avec la configuration de base
const AdminApi = axios.create({
  baseURL: "https://nehonixa-back-v2.onrender.com/api/Admin",
  withCredentials: true, // Permet l'envoi des cookies
});

// Intercepteur pour les requêtes sortantes
AdminApi.interceptors.request.use(
  (config) => {
    // Récupérer le token d'authentification depuis les cookies
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("authToken="))
      ?.split("=")[1];

    // Ajouter le token à l'en-tête Authorization si disponible
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Gestion des erreurs avant l'envoi de la requête
    if (error.code === "net::ERR_CONNECTION_RESET") {
      toast.error(
        "Erreur lors de l'envoi de la requête (connexion réinitialisée). c-28J2 "
      );
      console.error(
        "Erreur lors de l'envoi de la requête (connexion réinitialisée) : "
      );
    }

    // Ajout d'une gestion spécifique pour les erreurs réseau
    if (error.code === "ERR_NETWORK") {
      toast.error(
        "Erreur réseau détectée. " +
          "Vérifiez votre connexion et réessayez. " +
          "Si le problème persiste, contactez le support. " +
          "Erreur : ERR_NETWORK"
      );
    }

    // Gestion des erreurs HTTP 400
    if (error.response && error.response.status === 400) {
      return toast.error("Requête invalide (Code 400). Vérifiez vos données.");
    }
    if (error.response && error.response.status === 429) {
      window.location.href = ADMIN_ROUTES.Auth;
      return toast.error("Veuillez vous connectez.");
    }

    return Promise.reject(error); // Rejeter l'erreur pour une gestion ultérieure
  }
);

// Intercepteur pour les réponses entrantes
AdminApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // toast.error(error.response.data.message);
    console.error("Erreur capturée par Axios :", {
      code: error.code,
      message: error.message,
      stack: error.stack,
      response: error.response, // Toutes les données de la réponse
    });

    //Ajout de toast spécifique si certificat auto-signé
    if (
      error.code === "ERR_BAD_RESPONSE" ||
      error.message.includes("certificate")
    ) {
      console.log("Problème de certificat SSL détecté");
      // toast.error(
      //   "Problème de certificat SSL détecté. Assurez-vous que le serveur utilise un certificat valide."
      // );
    }

    return Promise.reject(error);
  }
);

export default AdminApi;
