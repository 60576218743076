import { useState } from "react";
import "./styles/TextEditor.css";
import {
  ContentStyle,
  CourseContent,
} from "../../NEHONIX/Interfaces/Interfaces";
import StyleToolBar from "./TextEditors/StyleToolBar";
import EditorFeatures from "./TextEditors/EditorFeatures";
import Preview from "./TextEditors/Preview";

function TextEditor() {
  const [courseTitle, setCourseTitle] = useState("");
  const [courseContents, setCourseContents] = useState<CourseContent[]>([]);
  const [currentStyles, setCurrentStyles] = useState<ContentStyle>({});
  const [preview, setPreview] = useState<boolean>(false);

  const applyStyle = (style: Partial<ContentStyle>) => {
    setCurrentStyles((prev) => ({ ...prev, ...style }));
  };

  return (
    <div className="create-course-container">
      <input
        type="text"
        value={courseTitle}
        onChange={(e) => setCourseTitle(e.target.value)}
        placeholder="Titre du cours"
        className="course-title-input"
      />

      <StyleToolBar
        applyStyle={applyStyle}
        currentStyles={currentStyles}
        setCourseContents={setCourseContents}
        preview={preview}
        setPreview={setPreview}
      />
      <EditorFeatures
        courseContents={courseContents}
        courseTitle={courseTitle}
        currentStyles={currentStyles}
        setCourseContents={setCourseContents}
        setCurrentStyles={setCurrentStyles}
      />
      {preview && <Preview courseContents={courseContents} />}
    </div>
  );
}

export default TextEditor;
